import React, {useState} from "react";
import styled from "styled-components";
import {Link} from "gatsby";
import breakpoints from "../../../styles/breakpoints";
import SubLinks from "./SubLinks";
import Icon from "../../Core/Icon";

const Section = styled.div`
  max-width: 80rem;
  margin: 0 auto;
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    padding: ${props => props.first ? "1rem 1.25rem" : "1rem"};
  }
`;

const Ul = styled.ul`
  list-style: none;
  padding-inline-start: 1rem;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${breakpoints.sm}) {
    padding-inline-start: 1.5rem;
  }
  
  @media only screen and (min-width: ${breakpoints.lg}) {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 1rem;
    padding:5rem 0px 0px;

  }
`;

const Li = styled.li`
  padding: 0.5rem 0;
  margin: 0 1rem 0 0 !important;
  
  @media only screen and (min-width: ${breakpoints.sm}) {
    margin: 0 1.5rem 0 0 !important;
  }
  
  @media only screen and (min-width: ${breakpoints.lg}) {
    padding: 0.5rem 0;
    
    &:last-of-type {
      padding-bottom: 0;
    }
  }
`;

const LinkStyled = styled(Link)`
  color: var(--white);
  text-decoration: none;
  font-weight: ${props => props.selected ? "700" : "normal"};
  white-space: nowrap;
  font-weight: 700;

  @media only screen and (min-width: ${breakpoints.lg}) {
    font-size: ${props => props.first ? "1.1rem" : "1rem"};
    font-weight: ${props => props.first && "700"};
    padding-bottom: ${props => props.first && "0.625rem"};
    border-bottom: ${props => props.first && "2px solid var(--secondary)"};
    white-space: ${props => props.first && "nowrap"};
    
  }
`;

const LinkBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const IconBox = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  transform: ${props => props.opened ? "rotate(180deg)" : "rotate(360deg)"};
  transition: .3s;
  cursor: pointer;
  display: block;
  margin: 0 2px 0 auto;
  
  svg {
    fill: ${props => props.opened ? "var(--secondary)" : "var(--white)"};
    width: 1.5rem;
    height: 1.5rem;
  }

  @media only screen and (min-width: ${breakpoints.lg}){
    display: none;
  }
`;

const Links = ({ navLinks, isDesktop, setIsMobileNavOpen, first}) => {
    const [linksOpen ,setLinksOpen] = useState(null);

    const toggleLink = (i, executeOnDesktop) => {
        if (isDesktop && executeOnDesktop) {
            setLinksOpen((linksOpen !== i) ? i : null);
        }

        if (!isDesktop && !executeOnDesktop){
            setLinksOpen((linksOpen !== i) ? i : null);
        }
    };

    return (
      <Section first={first}>
        <Ul first={first}>
          {navLinks.map((link, i) => {
                    const subLinks = link.links;
                    const opened = linksOpen === i;
                    if (link.showInNav !== false) {
                      return (
                        <Li
                          key={i}
                          onMouseEnter={() => toggleLink(i, true)}
                          onMouseLeave={() => toggleLink(i, true)}
                          first={first}
                        >
                          <LinkBlock opened={linksOpen === i}>
                            <LinkStyled
                              to={link.slug.current}
                              key={i}
                              aria-label={link.name}
                              selected={subLinks && opened}
                              onClick={() => setIsMobileNavOpen(false)}
                              first={first ? 1: 0}
                            >
                              {link.name}
                            </LinkStyled>
                            {subLinks && (
                            <IconBox onClick={() => toggleLink(i, false)} opened={opened}>
                              <Icon name="icon-arrow" />
                            </IconBox>
                          )}
                          </LinkBlock>
                          {subLinks && (<SubLinks opened={opened} subLinks={subLinks} isDesktop={isDesktop} setIsMobileNavOpen={setIsMobileNavOpen} first={first} />)}
                        </Li>
                      );
                  }
                })}
        </Ul>
      </Section>
    );
};

export default Links;