import React from "react";
import Helmet from "react-helmet";

export default function SEO(props) {

  const breadcrumbsArr = [];
  const listArr = props.path.split("/");
  let url = "https://www.holidayparks.com/";

  if (listArr.length > 2 ){
    listArr.forEach( (element, index) => {
      const obj = {};
      if (element) {
        obj["@type"] = "ListItem";
        obj.position = index;
        obj.name = element;
        obj.item =  `${url + element  }/`;
        url = `${url + element  }/`;
        breadcrumbsArr.push(obj);
      }
    });
  } else {
    const obj = {};
    obj["@type"] = "ListItem";
    obj.position = 1;
    obj.name = "home";
    obj.item =  url;
    breadcrumbsArr.push(obj);

  }
    
    return (
      <>
        <Helmet
          title={props.title}
          link={[
                  {
                    "rel": "icon", 
                    "type": "image/png", 
                    "href": props.favicon.asset.fixed.src
                  },
                  {
                    rel: "canonical",
                    href: `https://www.holidayparks.com${  props.path}`
                  }
                ]}
          meta={[
                    {
                      name: "description",
                      content: props.description
                    },
                    {
                      property: "og:title",
                      content: props.title
                    },
                    {
                      property: "og:description",
                      content: props.description
                    },
                    {
                      property: "og:image",
                      content: props.image.asset.fixed.src
                    },
                    {
                      name: "robots",
                      content: props.robots
                    }
                  ]
                    .concat(
                      props.keywords && props.keywords.length > 0 ? {
                            name: "keywords",
                            content: props.keywords.join(", ")
                          }
                        : []
                    )
                    .concat()}
        >
          {props.trustPilot && <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async />}

          <script type="application/ld+json">
            {`
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": ${JSON.stringify(breadcrumbsArr)}
          }
        `}
          </script>
        </Helmet>
      </>
    );
}