import React, {useCallback, useEffect, useState} from "react";
import {Link} from "gatsby";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import {isBrowser, setBodyOverflow} from "../../services/browser";
import NavLinks from "./Nav/NavLinks";
import Container from "../Core/Container";
import Icon from "../Core/Icon";

const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 4rem;
  width: 100%;
  background: transparent;
  position: ${props => props.isNavFixed ? "fixed" : "relative"};
  z-index: 9999;
  top: ${props => (((props.isNavFixed && props.visible) || props.isMobileNavOpen) ? "0" : (props.isNavFixed && !props.visible && "-4rem"))};
  transition: 0.3s;
  background: var(--primary);
  box-shadow: rgb(0 0 0 / 30%) -1px 6px 1rem -4px;
  
  &[data-show="true"] {
    position: fixed;
    top: 0;
  }

  @media only screen and (min-width: ${breakpoints.lg}){
    top: ${props => (props.isNavFixed && props.visible) ? "0" : (props.isNavFixed && !props.visible) && "-5.5rem"};
    height: 5.5rem;

    &[data-show="true"] {
      position: ${props => props.isNavFixed ? "fixed" : "relative"};
    }

    & > div {
      position: unset;
    }
  }
`;

const List = styled.div`
  display: inline-flex;
  width: 100%;
`;

const Button = styled.button`
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0 3px;
  
  svg {
    fill: var(--white);
    height: 18px;
    width: 18px;
  }
`;

const MobileNav = styled.div`
  height: ${props => props.isNavFixed ? "calc(100vh - 4rem)" : "calc(100vh - 6.75rem)"};
  position: fixed;
  transition: 0.3s;
  top: ${props => props.isNavFixed ? "4rem" : "6.75rem"};
  right: ${props => props.isMobileNavOpen ? "0" : "-100vw"};
  z-index: 999;
  background: rgba(0,0,0,0.9);
  width: 100%;
  overflow: scroll;
  
  @media only screen and (min-width: ${breakpoints.lg}){
    display: none;
  }
`;

const ListContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 4rem;
  width: 100%;

  @media only screen and (min-width: ${breakpoints.lg}) {
    height: 5.5rem;
  }
`;

const LogoLink = styled(Link)`
  height: 4rem;
  display: flex;
  align-items: center;

  @media only screen and (min-width: ${breakpoints.lg}){
    height: 5.5rem;
    width: 17rem;
    box-sizing: border-box;
  }
`;

const Img = styled.img`
  height: 2.75rem;
  object-fit: contain;
  
  @media only screen and (min-width: ${breakpoints.lg}){
    height: 3.75rem;
    width: 9.8rem;
  }
`;

const Background = styled.div`
  background: var(--primary);
  height: 4rem;
  transition: .3s;

  @media only screen and (min-width: ${breakpoints.lg}){
    height: 5.5rem;
  }
`;

const SearchBox = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0.5rem 0.75rem 1rem;
  box-sizing: border-box;
  background: var(--white);
  border: 1px solid var(--white);
  opacity: 0;
  margin: auto 1.25rem auto 2.5rem;
  border-radius: 0.625rem;
  width: 30rem;
  height: 3.125rem;
  cursor: ${props => props.visible ? "text" : "default"};
  
  p {
    margin-bottom: 0;
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    display: flex;
    opacity: ${props => props.visible && "1"};
    transition: .3s;
  }
  
  @media only screen and (min-width: 1050px) {
    width: 38rem;
  }

  @media only screen and (min-width: ${breakpoints.xl}) {
    width: 40rem;
  }
`;

const IconBox = styled.div`
  height: 2.375rem;
  width: 2.375rem;
  cursor: ${props => props.visible ? "pointer" : "default"};
    
  svg {
    height: 100%;
    width: 100%;
    fill: var(--primary);
  }
`;

const MobileIconBox = styled.div`
  display: block;
  position: fixed;
  bottom: ${props => props.isProperty ? "4.25rem" : "1.25rem"};
  right: 1.25rem;
  height: 3.125rem;
  width: 3.125rem;
  cursor: pointer;
  
  svg {
    height: 100%;
    width: 100%;
    fill: var(--secondary);
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const MOBILE_NAV_HEIGHT = 64; 
const DESKTOP_NAV_HEIGHT = 88;

const Nav = (props) => {
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const [navHeight, setNavHeight] = useState(MOBILE_NAV_HEIGHT);
    const [isDesktop, setIsDesktop] = useState(false);
    const [isNavFixed, setNavFixed] = useState(false);
    const [linksOpen ,setLinksOpen] = useState(null);
    const [searchBoxVisible, setSearchBoxVisible] = useState(false);

    const linksOpened = linksOpen !== null;
    const {isSearchForm, isProperty} = props;
    let pageHeight;
    let searchForm;

    const toggleLink = (i, executeOnDesktop) => {
        if (isDesktop && executeOnDesktop) {
            setLinksOpen((linksOpen !== i) ? i : null);
        }

        if (!isDesktop && !executeOnDesktop){
            setLinksOpen((linksOpen !== i) ? i : null);
        }
    };

    if (typeof window !== "undefined") {
        searchForm = document.getElementById("search-form");
    }

    const handleScroll = useCallback(() => {
        if (isBrowser()) {
            const { scrollY } = window;
            const scrollSensitivity = 7;

            if (typeof window !== "undefined") {
                pageHeight = window.innerHeight - 200;
            }

            if (prevScrollPos > (scrollY + scrollSensitivity) || (scrollY < navHeight)){
                setVisible(true);

                if ((prevScrollPos > pageHeight) && !searchBoxVisible){
                    setSearchBoxVisible(true);
                } else if ((prevScrollPos < pageHeight) && searchBoxVisible){
                    setSearchBoxVisible(false);
                }
            } else if (prevScrollPos < (scrollY - scrollSensitivity) && scrollY > navHeight){
                setVisible(false);
            }

            if (scrollY > 44 && isNavFixed === false){
                setNavFixed(true);
            } else if (scrollY <= 44 && isNavFixed === true) {
                setNavFixed(false);
            } else if (isNavFixed === false && linksOpened === true){
                setNavFixed(false);
            }

            setPrevScrollPos(scrollY);
        }
    }, [prevScrollPos, navHeight]);

    useEffect(() => {
        setBodyOverflow(isMobileNavOpen);
        const onResize = () => {
            setIsDesktop(window.innerWidth >= 992);
            const newNavHeight = isDesktop ? DESKTOP_NAV_HEIGHT : MOBILE_NAV_HEIGHT;

            if (!navHeight || (navHeight !== newNavHeight)) {
                setNavHeight(newNavHeight);
            }
            if (linksOpen !== null){
                setVisible(true);
            }
        };

        if (isBrowser()) {
            onResize();
            window.addEventListener("scroll", handleScroll);
            window.addEventListener("resize", onResize);
            return () => window.removeEventListener("scroll", handleScroll);
        }
    }, [prevScrollPos, visible, handleScroll, navHeight, isMobileNavOpen]);
    
    const scrollToForm = () => {
        if (searchForm !== null){
            const regionInput = document.getElementById("region");

            setTimeout(() => {
                if (typeof window !== "undefined") {
                    if (isDesktop && (searchBoxVisible === true)){
                        window.scrollTo({
                            top: searchForm.getBoundingClientRect().top + window.pageYOffset - 100,
                            behavior: "smooth",
                        });
                    }
                }
                regionInput.focus();
            }, 50);
        }

    };

    function setFocus(idInput){
        document.getElementById(idInput).focus();
    }

    const links = [
        {
            name: "Destinations",
            url: "",
            subLinks: props.regions,
        }
    ];

    return (
      <>
        {isNavFixed && <Background />}
        <NavContainer visible={visible} id="nav" isNavFixed={isNavFixed} isMobileNavOpen={isMobileNavOpen}>
          <Container>
            <List>
              <LogoLink to="/" onClick={() => setIsMobileNavOpen(false)}>
                <Img alt="Hottub Hideaways Logo" src={props.settings.logo.asset.fixed.src} />
              </LogoLink>
              {isSearchForm && (
              <SearchBox visible={searchBoxVisible} onClick={() => scrollToForm()}>
                <p>Start your search</p>
                <IconBox visible={searchBoxVisible}>
                  <Icon name="icon-loupe" />
                </IconBox>
              </SearchBox>
            )}

              <ListContent>
                {!isDesktop &&(
                <Button aria-label="Open mobile menu" onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}>
                    {isMobileNavOpen ? <Icon name="icon-cross" /> : <Icon name="icon-burger" />}
                </Button>
                )}
                {isDesktop && (
                <NavLinks linksOpen={linksOpen} toggleLink={toggleLink} links={links} isDesktop={isDesktop} headerVisible={visible} />
                )}
              </ListContent> 
            </List>
          </Container>
          {!isDesktop && (
          <MobileNav isMobileNavOpen={isMobileNavOpen} isNavFixed={isNavFixed}>
            <NavLinks linksOpen={linksOpen} toggleLink={toggleLink} links={links} isDesktop={isDesktop} setIsMobileNavOpen={setIsMobileNavOpen} headerVisible={visible} />
          </MobileNav>
              )}
          {isSearchForm && (
          <MobileIconBox onClick={() => setFocus("region")} isProperty={isProperty}>
            <Icon name="icon-loupe" />
          </MobileIconBox>
           )}
        </NavContainer>
      </>
    );
};

export default Nav;