import React, {useState} from "react";
import styled from "styled-components";
import {Link} from "gatsby";
import breakpoints from "../../../styles/breakpoints";
import Icon from "../../Core/Icon";

const Ul = styled.ul`
  display: ${props => props.opened ? "flex" : "none"};
  flex-direction: column;
  list-style: none;
  padding-inline-start: 1rem;
  margin-top: 0.5rem;

  @media only screen and (min-width: ${breakpoints.sm}) {
    padding-inline-start: 1.5rem;
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    display: ${props => props.opened || props.first ? "flex" : "none"};
    padding-inline-start: 0;
  }
`;

const Li = styled.li`
  padding: 0.5rem 0;
  margin: 0 1rem 0 0 !important;

  @media only screen and (min-width: ${breakpoints.sm}) {  
    margin: 0 1.5rem 0 0 !important;
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    margin: 0 !important;
  }
`;

const SubUl = styled(Ul)`
  padding-inline-start: 1rem;
  padding-top: 0.5rem;
  
  li {
    padding: 0.25rem 0;
  }

  @media only screen and (min-width: ${breakpoints.sm}) {
    padding-inline-start: .5rem;
  }
`;

const LinkBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LinkStyled = styled(Link)`
  color: var(--white);
  text-decoration: none;
  font-weight: ${props => props.selected ? "700" : "normal"};

  @media only screen and (min-width: ${breakpoints.lg}) {
    font-size: 0.875rem;
    
    &:hover {
      font-weight: 700;
    }
  }
`;

const IconBox = styled.div`
  width: 1rem;
  height: 1rem;
  transform: ${props => props.opened ? "rotate(180deg)" : "rotate(360deg)"};
  transition: .3s;
  cursor: pointer;
  display: block;
  margin: 0 2px 0 auto;
  
  svg {
    fill: ${props => props.opened ? "var(--secondary)" : "var(--white)"};
    width: 100%;
    height: 100%;
  }
  
  &:hover {
    transform: rotate(180deg);
    transition: .3s;
  }
`;

const SubLinks = ({opened, subLinks, isDesktop, setIsMobileNavOpen, first}) => {
    const [linksOpen ,setLinksOpen] = useState(null);

    const toggleLink = (i, executeOnDesktop) => {
        if ((isDesktop && executeOnDesktop) || (!isDesktop && !executeOnDesktop) || (!isDesktop && executeOnDesktop)) {
            setLinksOpen((linksOpen !== i) ? i : null);
        }
    };

    return (
      <Ul opened={opened} first={first}>
        {subLinks.map((subLink, i) => {
            const {links} = subLink;
            const linksOpened = linksOpen === i;

             return (
               <Li key={i}>
                 <LinkBlock opened={linksOpened}>
                   <LinkStyled
                     to={subLink.url}
                     key={i}
                     aria-label={subLink.name}
                     selected={links && linksOpened}
                     onClick={() => setIsMobileNavOpen(false)}
                   >
                     {subLink.name}
                   </LinkStyled>
                   {links && (
                   <IconBox onClick={() => toggleLink(i, true)} opened={linksOpened}>
                     {linksOpened ? <Icon name="icon-minus" /> : <Icon name="icon-plus" />}
                   </IconBox>
                   )}
                 </LinkBlock>
                 {links && (
                 <SubUl opened={linksOpened}>
                   {links.map((link, j) => {
                          return (
                            <li key={j}>
                              <LinkStyled
                                to={link.url}
                                key={j}
                                aria-label={link.name}
                                onClick={() => setIsMobileNavOpen(false)}
                              >
                                {link.name}
                              </LinkStyled>
                            </li>
                          );
                      })}
                 </SubUl>
                  )}
               </Li>
              );
          })}
      </Ul>
    );
};

export default SubLinks;